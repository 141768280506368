@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('styles/leaflet/leaflet.css');

html {
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  color: #142837;
}

body {
  margin: 0;
  font-family: 'roc-grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  outline: none;
}

input:hover {
  outline: none;
}

input::placeholder {
  font-size: 14px !important;
  color: #6B778C;
}

textarea::placeholder {
  font-size: 14px !important;
  color: #6B778C;
}

select {
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='%23CCC'/></g></svg>") no-repeat;
  background-position: center right 10px;
  background-size: 15px auto;
  background-color: #fff;
}

/* ======== start satoshi ======= */
@font-face {
  font-family: "Satoshi";
  src: url("./styles/fonts/satoshi/Satoshi-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Satoshi-light";
  src: url("./styles/fonts/satoshi/Satoshi-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Satoshi-medium";
  src: url("./styles/fonts/satoshi/Satoshi-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Satoshi-bold";
  src: url("./styles/fonts/satoshi/Satoshi-Bold.ttf");
  font-display: swap;
}

/* ======== end satoshi ======= */

/* ======== start gilroy ======= */
@font-face {
  font-family: "Gilroy-regular";
  src: url("./styles/fonts/gilroy/Gilroy-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-medium";
  src: url("./styles/fonts/gilroy/Gilroy-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-semibold";
  src: url("./styles/fonts/gilroy/Gilroy-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-bold";
  src: url("./styles/fonts/gilroy/Gilroy-Bold.ttf");
  font-display: swap;
}
/* ======== end gilroy ======= */

/* ======== start roc-grotesk ======= */
@font-face {
  font-family: "rocGrotesk-light";
  src: url("./styles/fonts/rocGrotesk/RocGrotesk-Light.otf");
  font-display: swap;
}

@font-face {
  font-family: "rocGrotesk-regular";
  src: url("./styles/fonts/rocGrotesk/RocGrotesk-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "rocGrotesk-medium";
  src: url("./styles/fonts/rocGrotesk/RocGrotesk-Medium.otf");
  font-display: swap;
}

@font-face {
  font-family: "rocGrotesk-bold";
  src: url("./styles/fonts/rocGrotesk/RocGrotesk-Bold.otf");
  font-display: swap;
}
/* ======== end roc-grotesk ======= */

button {
  background: #061130;
  color: white;
  padding: 6px 18px;
  border-radius: 4px;

}

.leaflet-container {
  height: 100%;
}

.leaflet-control-attribution {
  display: none !important;
}

.leaflet-control {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

[alt="Google"] {
  display: none !important;
}

.shadow-primary {
  box-shadow: 0px 17.3035px 20.7642px -3.4607px rgba(16, 24, 40, 0.08), 0px 6.92141px 6.92141px -3.4607px rgba(16, 24, 40, 0.03);;
}


.folder-icon::before {
  content: '\ed57' !important
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fancy-border {
  border-radius: 50%;
  border: 3px solid;
  
  border-bottom-color: #FF6224;
  border-left-color: #16C6A4;
  border-right-color: #736CED;
  border-top-color: #FF6224;
  /* border-image: radial-gradient(to right, #FF6224 33.33%, #736CED 33.33%, #16C6A4 33.33%) 3; */
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    font-family: 'rocGrotesk-medium', sans-serif;
}

input:focus{
  border-color: #142837;
  border-width: 1.5px;
}

textarea:focus{
  border-color: #142837;
}

.number-pagination .break{
  border-left: 1px solid #D8D8D8;

  padding: 5px 10px;
  border-width: 1.5px;
}

.loader-bar {
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background-color: #16C6A4;
  animation: loader-animation 5s linear infinite;
}

@keyframes loader-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}